@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

/*
We want a permanent scroll bar to avoid horizontal shifting.
Placing this on html instead of body. This is to avoid horizontal shifting
when opening and closing a headless ui dialog.

https://github.com/tailwindlabs/headlessui/issues/762
*/
html {
  overflow-y: scroll;
}
